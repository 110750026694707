import React, { useEffect, useState } from 'react';
import MiddleEllipsis from "react-middle-ellipsis";

import { useAuthContext } from '../../../hooks/useAuthContext';
import { useLaunchGame } from '../../../hooks/useLaunchGame';

import HCLogo from '../../../assets/img/logo-square.png';

const SlotMachine = () => {

	const { user } = useAuthContext();

	const { startGame, isLoading, error, gameLink } = useLaunchGame();

	const [isOpenGame, setIsOpenGame] = useState(false);
	const openGame = () => {
    setIsOpenGame(true);
		// setSelectedGameCode(game._id);
  };

	const [selectedGameCode, setSelectedGameCode] = useState('');
	const chooseGameLaunch = async () => {
		if (selectedGameCode == '') {
			return;
		}
		await startGame(user.token, selectedGameCode);
		var element = document.getElementById("gameFrame");
		element.scrollIntoView();
    };

	const [gamelist, setGameList] = useState([]);
	const getGameList = async () => {
	  const response = await fetch('https://testapi.hiclub33.io/api/game/getAllGames?category=slot&language=en', {
		headers: {
		  'Authorization': `Bearer ${user.token}`
		}
	  });
	  const json = await response.json();
	  if (response.ok) {
		console.log(json);
		setGameList(json);
	  }
	}


	const [userData, setUserData] = useState();
	const getUserData = async () => {
	  const response = await fetch('https://testapi.hiclub33.io/api/player/id/'+user.player_id, {
		headers: {
		  'Authorization': `Bearer ${user.token}`
		}
	  });
	  const json = await response.json();
	  if (response.ok) {
		setUserData(json);
	  }
	}
	
	useEffect(() => {
		if (user) {
			getUserData();
			getGameList();
		}
	
	}, [user]);


	// Pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [gamesPerPage, setGamesPerPage] = useState(30);

	const totalPages = Math.ceil(gamelist.length / gamesPerPage);
	const startIndex = (currentPage - 1) * gamesPerPage;
	const currentGames = gamelist.slice(startIndex, startIndex + gamesPerPage);

	const getTruncatedPages = () => {
		const totalShownPages = 5; // Max number of page links to show, including current and boundaries
		const pages = [];
		
		if (totalPages <= totalShownPages) {
			// If total pages are less than or equal to totalShownPages, show all pages
			for (let i = 1; i <= totalPages; i++) {
			pages.push(i);
			}
		} else {
			// When there are more pages, show truncated pagination
			const leftEdge = Math.max(1, currentPage - 1); // 1 page before the current
			const rightEdge = Math.min(totalPages, currentPage + 1); // 1 page after the current

			// Always include first page
			if (leftEdge > 2) {
			pages.push(1, '...');
			} else {
			for (let i = 1; i < leftEdge; i++) {
				pages.push(i);
			}
			}

			// Include the current page and its neighbors
			for (let i = leftEdge; i <= rightEdge; i++) {
			pages.push(i);
			}

			// Always include the last page
			if (rightEdge < totalPages - 1) {
			pages.push('...', totalPages);
			} else {
			for (let i = rightEdge + 1; i <= totalPages; i++) {
				pages.push(i);
			}
			}
		}

		return pages;
	};


  	return (
		<>

			
			<div className="container">

			{ isOpenGame && (
				<div
					className="modal fade show"
					style={{ display: "block", paddingTop: "100px", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
					aria-modal="true"
					role="dialog"
				>
					<div className="modal-dialog modal-dialog-top" role="document">
						<div className="modal-content bg-black p-3">
							<div className="modal-header" style={{ borderBottom:'1px solid #e9d096'}}>
								<h5 className="modal-title brand-gold"></h5>
								<button
									type="button"
									className="btn-close bg-transparent"
									aria-label="Close"
									onClick={() => setIsOpenGame(false)}
									style={{ border:'none', fontSize:'18px' }}
								>
									<i className="fa-solid fa-circle-xmark brand-gold"></i>
								</button>
							</div>
							<div className="modal-body">
								<p className="text-white text-center">
									Do you wish to proceed with this credit?
									<br/>
									<strong>{userData && parseFloat(Number(userData.cashBalance)+Number(userData.creditBalance)).toFixed(2)}</strong>
								</p>
								<button className="btn btn-block text-start bg-gold" onClick={(e) => {
									chooseGameLaunch();
									setIsOpenGame(false);
								}}>
									Yes
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

				<div className="row px-2 my-3">
					{gameLink && gameLink != '' ? (
						<div className="col-12 px-2 mt-3">
							<div id="gameFrame" className="game-frame">
								<iframe className="game-frame-item" src={gameLink} allowFullScreen></iframe>
							</div>
						</div>
					):(
						<></>
					)}


					<div className="col-12 mt-3">
						<h4 className="text-gray mb-0">Slots</h4>
					</div>

					{currentGames && currentGames.length > 0 ? (
						<div className="col-12 mt-3">
							{/* Pagination Controls */}
							<div className="col-12 col-sm-9 col-lg-6 col-xl-4 pagination-container">
								<button
								onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
								disabled={currentPage === 1}
								>
								<i className="fa-solid fa-angle-left"></i>
								</button>

								{getTruncatedPages().map((page, index) => (
								<button
									key={index}
									onClick={() => page !== '...' && setCurrentPage(page)}
									disabled={page === '...'}
									className={currentPage === page ? 'active' : ''}
								>
									{page}
								</button>
								))}

								<button
								onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
								disabled={currentPage === totalPages}
								>
								<i className="fa-solid fa-angle-right"></i>
								</button>
							</div>
							<div className="row">
								{currentGames && currentGames.map((game, index) => (
									<>
										<div className="col-4 col-sm-3 col-lg-2 px-0 text-center" key={index}>
											{/* <button className="btn p-1" onClick={(e) => {
												setSelectedGameCode(game._id);
												chooseGameLaunch();
											}}> */}
											<button className="btn p-1" onClick={(e) => {
												openGame();
												setSelectedGameCode(game._id);
											}}>
												<div className="game-container">
													<img className="w-100" src={game.gameIcon != "" && game.gameIcon != null ? game.gameIcon : HCLogo }></img>
												</div>
											</button>
											
										</div>
									</>
								))}
							</div>

							

						</div>
					):(
						<div className="col-12 mt-3">
							<p className="text-gray">No games available for this category.</p>
						</div>
					)}

		
				</div>
			</div>
		</>
  	);

};

export default SlotMachine;