import React, { Component } from "react";
import { NavLink, Link } from 'react-router-dom';

import FooterLogo from '../assets/img/logotext.png'

import BrandLogo from './brand-logo';
import FAQ from "./faq";

class Footer extends Component {
  render() {
    return (
      <>
        <div className="container" >
          <hr className="divider"></hr>
          <BrandLogo></BrandLogo>
          <hr className="divider"></hr>
          <FAQ />
          <hr className="divider"></hr>
          <div className="row pt-3 pb-5">
            <div className="col-12">
              <img className="footer-logo" src={FooterLogo}></img>
            </div>
            <div className="col-12">
              <p className="light-yellow mt-3 mb-0">HICLUB33 Online Casino</p>
            </div>
            <div className="col-lg-6">
              <p className="text-gray" style={{fontSize:"12px"}}>Casinos online have not always been around, but we can safely say that online casinos have been used a lot since they came on the market. And it's not in short demand nor options, and now in {(new Date().getFullYear())}, we have 1000s and 1000s to pick from – it's just a matter of what you like and what payment options you would like to see at the casino.</p>
              <p className="text-gray" style={{fontSize:"12px"}}>Players are always looking for something new, which will help make the gaming experience so much better and more accessible. Allowing the player to focus on the absolute fun of a casino, that's right, the games themselves.</p>
            </div>
            <div className="col-lg-6">
              <p className="text-gray" style={{fontSize:"12px"}}>Your use of and access to HiCLUB33 Online Casino signifies that you fully understand and agree to be legally bound by the contents of our Terms of Service and Responsible Gaming Policy.</p>
              <p className="text-gray" style={{fontSize:"12px"}}>Crypto trading is not gambling by definition, therefore it is not covered by our gaming license.</p>
            </div>
          </div>
        </div>
        <div className="container-fluid copyright-footer">
          <div className="row">
            <div className="col-12 text-center">
              <p className="copyright-text">&copy; {(new Date().getFullYear())} All Rights Reserved | HiClub33</p>
            </div>
          </div>
        </div>
      </>
    );
  }
}
  
export default Footer;