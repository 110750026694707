import Polyglot from 'node-polyglot';
import en from './locales/en.json';
import cn from './locales/cn.json';
import bm from './locales/bm.json';

const languages = {
  en,
  cn,
  bm
};

let polyglot = new Polyglot({ phrases: languages['en'], locale: 'en' });

export const setLanguage = (language) => {
  if (languages[language]) {
    polyglot = new Polyglot({ phrases: languages[language], locale: language });
  } else {
    console.warn(`Language ${language} not found, falling back to English.`);
    polyglot = new Polyglot({ phrases: languages['en'], locale: 'en' });
  }
};

export const t = (key) => polyglot.t(key);

export default polyglot;
