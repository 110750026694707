import React, { Component } from "react";
import { NavLink, Link } from 'react-router-dom';

class FAQ extends Component {
  render() {
    return (
      <div className="container py-5">
        <h1 className="brand-gold mb-4 text-center">FAQ</h1>
        <div className="accordion border-0" id="accordionExample">

          <div className="card border-0">
            <div className="card-header bg-purpleblack" id="headingOne">
              <h2 className="mb-0">
                <button className="dropdown-button font-weight-bold btn btn-link btn-block text-left text-decoration-none brand-gold" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Begin Your Gaming Journey
                </button>
              </h2>
            </div>
            <div id="collapseOne" className="collapse show bg-gold" aria-labelledby="headingOne" data-parent="#accordionExample">
              <div className="card-body">
                Can I register on your website?
                <br/><br/>
                You must be at least 18 years old or have reached the age of majority in your jurisdiction. You must also be legally permitted to play online games according to the laws that apply to you. For more information, please read our terms and conditions.
                <br/><br/>
                Gaming can be addictive, and players are advised to exercise self-control.
              </div>
            </div>
          </div>

          <div className="card border-0">
            <div className="card-header bg-purpleblack" id="headingTwo">
              <h2 className="mb-0">
                <button className="dropdown-button font-weight-bold btn btn-link btn-block text-left text-decoration-none brand-gold collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Account Information
                </button>
              </h2>
            </div>
            <div id="collapseTwo" className="collapse bg-gold" aria-labelledby="headingTwo" data-parent="#accordionExample">
              <div className="card-body">
                <strong>1. What if I forget my password?</strong>
                <br/>
                If you forget your password, you can reset it within 15 seconds via our "Forgot Password" link. After requesting a password reset, please follow the instructions in the email we send to complete the process.
                <br/><br/>
                <strong>2. I have lost my mobile phone. How do I reset my Google Authenticator?</strong>
                <br/>
                If you need to remove your Google Authenticator 2FA, please contact us. Once we receive your request, you will need to correctly answer several security questions for the safety of your account before we can remove the 2FA.
                <br/><br/>
                <strong>3. Can I change my username or registered email address?</strong>
                <br/>
                Unfortunately, we cannot update this information. If you need to change your username or registered email, we recommend closing your current account and opening a new one.
                <br/><br/>
                <strong>4. How do I become a VIP?</strong>
                <br/>
                Membership in our exclusive VIP club is by invitation only. Once you begin your gaming journey, you will be notified of your VIP status via email shortly.
              </div>
            </div>
          </div>

          <div className="card border-0">
            <div className="card-header bg-purpleblack" id="headingThree">
              <h2 className="mb-0">
                <button className="dropdown-button font-weight-bold btn btn-link btn-block text-left text-decoration-none brand-gold collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  My wallet
                </button>
              </h2>
            </div>
            <div id="collapseThree" className="collapse bg-gold" aria-labelledby="headingThree" data-parent="#accordionExample">
              <div className="card-body">
                "My Wallet" is a component of the player account. Here, you can view the balance of all supported cryptocurrencies, make deposits and withdrawals, and check transaction records.
              </div>
            </div>
          </div>

          <div className="card border-0">
            <div className="card-header bg-purpleblack" id="headingFour">
              <h2 className="mb-0">
                <button className="dropdown-button font-weight-bold btn btn-link btn-block text-left text-decoration-none brand-gold collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  How to deposit
                </button>
              </h2>
            </div>
            <div id="collapseFour" className="collapse bg-gold" aria-labelledby="headingFour" data-parent="#accordionExample">
              <div className="card-body">
                1. Find the『My wallet』 page, click the deposit page, copy the wallet address, or scan QR code for payment.
                <br/>
                2. Use Buy Crypto to deposit any supported currencies provided by MoonPay and Banxa.
                <br/>
                3. Quickly deposit and withdrawal directly from the cooperative wallet.
              </div>
            </div>
          </div>

          <div className="card border-0">
            <div className="card-header bg-purpleblack" id="headingFive">
              <h2 className="mb-0">
                <button className="dropdown-button font-weight-bold btn btn-link btn-block text-left text-decoration-none brand-gold collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  How to withdraw
                </button>
              </h2>
            </div>
            <div id="collapseFive" className="collapse bg-gold" aria-labelledby="headingFive" data-parent="#accordionExample">
              <div className="card-body">
                Find the『My wallet』page, open the withdraw page, enter the address of the wallet you need to withdraw and the amount of cryptocurrency (pay attention to the fee).
              </div>
            </div>
          </div>
          
          <div className="card border-0">
            <div className="card-header bg-purpleblack" id="headingSix">
              <h2 className="mb-0">
                <button className="dropdown-button font-weight-bold btn btn-link btn-block text-left text-decoration-none brand-gold collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                  Minimum Withdraw amount
                </button>
              </h2>
            </div>
            <div id="collapseSix" className="collapse bg-gold" aria-labelledby="headingSix" data-parent="#accordionExample">
              <div className="card-body">
                Because the value of each cryptocurrency is different, the minimum Withdraw amount is also different
              </div>
            </div>
          </div>
          
          <div className="card border-0">
            <div className="card-header bg-purpleblack" id="headingSeven">
              <h2 className="mb-0">
                <button className="dropdown-button font-weight-bold btn btn-link btn-block text-left text-decoration-none brand-gold collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                  How long does it take to deposit and withdraw?
                </button>
              </h2>
            </div>
            <div id="collapseSeven" className="collapse bg-gold" aria-labelledby="headingSeven" data-parent="#accordionExample">
              <div className="card-body">
                Each transaction on the blockchain requires several cycles to confirm the transfer has been recorded successfully.
                <br/><br/>
                Generally speaking, each transaction requires 5-10 minutes before it can be confirmed by the blockchain network.
                <br/><br/>
                If you encounter any problem during deposit or Withdraw, you can visit www.blockchain.info to check your transaction, or contact technical support.
              </div>
            </div>
          </div>
          
          <div className="card border-0">
            <div className="card-header bg-purpleblack" id="headingEight">
              <h2 className="mb-0">
                <button className="dropdown-button font-weight-bold btn btn-link btn-block text-left text-decoration-none brand-gold collapsed" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                  Before Withdraw, how many confirmations is required on my deposit?
                </button>
              </h2>
            </div>
            <div id="collapseEight" className="collapse bg-gold" aria-labelledby="headingEight" data-parent="#accordionExample">
              <div className="card-body">
                At least 3 confirmations of your entire deposit are required before a withdrawal. You may check the current confirmation progress by clicking on the deposit link in the cashier page.
              </div>
            </div>
          </div>
          
          <div className="card border-0">
            <div className="card-header bg-purpleblack" id="headingNine">
              <h2 className="mb-0">
                <button className="dropdown-button font-weight-bold btn btn-link btn-block text-left text-decoration-none brand-gold collapsed" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                  Where do transaction confirmations come from?
                </button>
              </h2>
            </div>
            <div id="collapseNine" className="collapse bg-gold" aria-labelledby="headingNine" data-parent="#accordionExample">
              <div className="card-body">
                All confirmation information comes from the wallet supplier, the blockchain and miners.
              </div>
            </div>
          </div>
          
          <div className="card border-0">
            <div className="card-header bg-purpleblack" id="headingTen">
              <h2 className="mb-0">
                <button className="dropdown-button font-weight-bold btn btn-link btn-block text-left text-decoration-none brand-gold collapsed" type="button" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                  How long does it take to confirm a transaction?
                </button>
              </h2>
            </div>
            <div id="collapseTen" className="collapse bg-gold" aria-labelledby="headingTen" data-parent="#accordionExample">
              <div className="card-body">
                It depends on the blockchain and your transfer fee. It might take 10 minutes or several hours.
              </div>
            </div>
          </div>
          
          <div className="card border-0">
            <div className="card-header bg-purpleblack" id="headingEleven">
              <h2 className="mb-0">
                <button className="dropdown-button font-weight-bold btn btn-link btn-block text-left text-decoration-none brand-gold collapsed" type="button" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                  Is your game fair and just?
                </button>
              </h2>
            </div>
            <div id="collapseEleven" className="collapse bg-gold" aria-labelledby="headingEleven" data-parent="#accordionExample">
              <div className="card-body">
                We are based on the Ethereum smart contract. All data and core logic on the blockchain are transparent, and manipulation is not possible. Every single transaction on the blockchain can be tracked on etherscan. The fairness, openness, and justness of us gaming platform are guaranteed.
              </div>
            </div>
          </div>
          
          <div className="card border-0">
            <div className="card-header bg-purpleblack" id="headingTwelve">
              <h2 className="mb-0">
                <button className="dropdown-button font-weight-bold btn btn-link btn-block text-left text-decoration-none brand-gold collapsed" type="button" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                  What should I do if my game hangs or there is a problem?
                </button>
              </h2>
            </div>
            <div id="collapseTwelve" className="collapse bg-gold" aria-labelledby="headingTwelve" data-parent="#accordionExample">
              <div className="card-body">
                If you encounter any technical problem while playing our games, please try to refresh the game. Normally it will work after refresh. Should the problem persist, please contact us.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
  
export default FAQ;