import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AuthContextProvider } from './context/authContext';
import { UserContextProvider } from './context/userContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import "./assets/css/bootstrap.min.css";
import "./assets/css/theme.css";
import "./assets/css/style.css"; 

const CLIENT_ID = "195321194346-1jsdl20ookmko4psts5npjval1b10gil.apps.googleusercontent.com"

ReactDOM.render(
  <AuthContextProvider>
    <UserContextProvider>
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <App />
      </GoogleOAuthProvider>
    </UserContextProvider>
  </AuthContextProvider>,
  document.getElementById("root")  
);