import React from "react";

const ContactUsIcon = () => {
  
  return (
  	<div className="floating-btn">
      <button className="btn contact-btn"
        onClick={() => {
          window.open("https://wa.me/601111827726", "_blank");
        }}
      >
        <span className="contact-icon"><i className="fa-solid fa-headset"></i></span>
      </button>
    </div>
  );

};

export default ContactUsIcon;