import React, { useState } from "react";

const BetsTable = () => {
  const [activeTab, setActiveTab] = useState("latestBets");

  // Data for each tab
  const latestBets = [
    { game: "Game 1", amount: "$500", player: "Player A", multiplier: "2x", profit: "$100" },
    { game: "Game 2", amount: "$1000", player: "Player B", multiplier: "1.5x", profit: "$75" },
    { game: "Game 3", amount: "$750", player: "Player C", multiplier: "3x", profit: "$200" },
  ];

  const highRoller = [
    {
      game: "Game 4",
      player: "Player X",
      betAmount: "$500",
      multiplier: "2.5x",
      profit: "$1250",
    },
    {
      game: "Game 5",
      player: "Player Y",
      betAmount: "$1000",
      multiplier: "1.8x",
      profit: "$1800",
    },
    {
      game: "Game 6",
      player: "Player Z",
      betAmount: "$750",
      multiplier: "3x",
      profit: "$2250",
    },
  ];

  const wagerContest = {
    contestPrizePool: "$10,000",
    timeRemaining: "23:59:59",
    lastWinner: { username: "Player W", profitPercentage: "20%", profitAmount: "$2000" },
    weeklyDate: "Jan 1 - Jan 7",
    runnersUp: [
      { rank: 1, player: "Player Q", wager: "$3000", prize: "$1000" },
      { rank: 2, player: "Player R", wager: "$2500", prize: "$800" },
      { rank: 3, player: "Player S", wager: "$2000", prize: "$600" },
    ],
  };

  // Render table based on active tab
  const renderTableContent = () => {
    if (activeTab === "latestBets") {
      return (
        <div className="bets-table">
          <table className="w-100 table text-white" style={{ tableLayout: "fixed" }}>
            <thead>
              <tr>
                <th style={{ width: "20%" }}>Game</th>
                <th style={{ width: "20%" }}>Player</th>
                <th style={{ width: "20%" }}>Bet Amount</th>
                <th style={{ width: "20%" }}>Multiplier</th>
                <th style={{ width: "20%" }}>Profit</th>
              </tr>
            </thead>
            <tbody>
              {latestBets.map((bet, index) => (
                <tr key={index}>
                  <td>{bet.game}</td>
                  <td>{bet.amount}</td>
                  <td>{bet.player}</td>
                  <td>{bet.multiplier}</td>
                  <td>{bet.profit}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else if (activeTab === "highRoller") {
      return (
        <div className="roller-table">
          <table className="w-100 table text-white roller-table" style={{ tableLayout: "fixed" }}>
            <thead>
              <tr>
                <th style={{ width: "20%" }}>Game</th>
                <th style={{ width: "20%" }}>Player</th>
                <th style={{ width: "20%" }}>Bet Amount</th>
                <th style={{ width: "20%" }}>Multiplier</th>
                <th style={{ width: "20%" }}>Profit</th>
              </tr>
            </thead>
            <tbody>
              {highRoller.map((roll, index) => (
                <tr key={index}>
                  <td>{roll.game}</td>
                  <td>{roll.player}</td>
                  <td>{roll.betAmount}</td>
                  <td>{roll.multiplier}</td>
                  <td>{roll.profit}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else if (activeTab === "wagerContest") {
      return (
        <>
          <div className="d-flex justify-content-between mb-2">
            <div className="wager-top-content mr-2">
              <strong>Contest Prize Pool:</strong> {wagerContest.contestPrizePool}
            </div>
            <div className="wager-top-content mr-2">
              <strong>Time Remaining:</strong> {wagerContest.timeRemaining}
            </div>
            <div className="wager-top-content">
              <strong>Last Winner:</strong> {wagerContest.lastWinner.username} (
              {wagerContest.lastWinner.profitPercentage},{" "}
              {wagerContest.lastWinner.profitAmount})
            </div>
          </div>
          <div className="wager-table">
            <h5 style={{padding:"12px", textAlign:"center"}}>This Week: {wagerContest.weeklyDate}</h5>
            <table className="w-100 table text-white" style={{ tableLayout: "fixed" }}>
              <thead>
                <tr>
                  <th style={{ width: "25%" }}>Runner Up</th>
                  <th style={{ width: "25%" }}>Player</th>
                  <th style={{ width: "25%" }}>Wager</th>
                  <th style={{ width: "25%" }}>Prize</th>
                </tr>
              </thead>
              <tbody>
                {wagerContest.runnersUp.map((runner, index) => (
                  <tr key={index}>
                    <td>{runner.rank}</td>
                    <td>{runner.player}</td>
                    <td>{runner.wager}</td>
                    <td>{runner.prize}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      );
    }
  };

  return (
    <div className="container my-5 p-0 text-white">
      <div className="d-block d-sm-flex justify-content-between">
        <h6 className="mb-3 mb-sm-0">Latest bet and Race</h6>
        <ul className="tabs">
          <li className="nav-item">
            <button
              className={`tabs-link ${activeTab === "latestBets" ? "tab-active" : ""}`}
              onClick={() => setActiveTab("latestBets")}
            >
              Latest Bets
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`tabs-link ${activeTab === "highRoller" ? "tab-active" : ""}`}
              onClick={() => setActiveTab("highRoller")}
            >
              High Roller
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`tabs-link ${activeTab === "wagerContest" ? "tab-active" : ""}`}
              onClick={() => setActiveTab("wagerContest")}
            >
              Wager Contest
            </button>
          </li>
        </ul>
      </div>
      <div className="w-100 mt-4">{renderTableContent()}</div>
    </div>
  );
};

export default BetsTable;
