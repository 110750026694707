import { useState } from 'react';

export const useDeposit = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [paymentLink, setPaymentLink] = useState(null);

    const playerCreateDeposit = async (authToken, userID, username, referrer, depositType, selectedGateway, amount, promoState) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch('https://testapi.hiclub33.io/api/deposit/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
			body: JSON.stringify({
                user_id: userID,
                username,
                referrer,
                type: depositType,
                gateway: selectedGateway,
                txn_code: "Pending",
                req_amount: amount,
                txn_amount: 0,
                promo: promoState,
                promo_id: "",
                promo_amount: 0
            })
        })
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
			setError(json.error);
		}
		if (response.ok) {
            setIsLoading(false);
            window.location.href = json.redirect_url;
            // setPaymentLink(json.redirect_url);
		}
    }

    return { playerCreateDeposit, paymentLink, isLoading, error};
}