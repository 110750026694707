// Dependencies
import React, { useRef, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses } from 'react-pro-sidebar';
import { NavLink } from 'react-router-dom';

// Hooks
import { useLogout } from './hooks/useLogout';
import { useAuthContext } from './hooks/useAuthContext';
import { useDeposit } from './hooks/useDeposit';

// Components
import ContactUsIcon from "./components/contact-us-icon";
import Home from "./components/home";
// import Transactions from "./components/transactions";
import Footer from "./components/footer";

// Pages
import Login from "./pages/login";
import Register from "./pages/register";
import PlayerProfile from "./pages/player-profile";
import TermsAndCondition from "./pages/terms-and-condition";
import PrivacyPolicy from "./pages/privacy-policy";

import SlotMachine from "./pages/game/category/slot-machine";
import FishingGame from "./pages/game/category/fishing";
import MobileSlotGame from "./pages/game/category/mobile-slot-game";
import BingoGame from "./pages/game/category/bingo";
import CasinoGame from "./pages/game/category/casino";
import PokerGame from "./pages/game/category/poker";
import TableGame from "./pages/game/category/table-game";
import ArcadeGame from "./pages/game/category/arcade";

import PlayerWallet from "./pages/wallet";
import Promotions from "./pages/promotions";
import Bonus from "./pages/bonus";

import RecentPage from "./pages/recent";

// Bonus Reward
import Affiliate from "./pages/affiliate";
import Leaderboard from "./pages/leaderboard";

// Translation
import { setLanguage, t } from './translation.js';

// Images
import SquareLogo from './assets/img/logo-square.png';
import MainLogo from './assets/img/logotext.png';
import SearchIcon from './assets/img/search-icon.png';
import LoginIcon from './assets/img/login-icon.png';
import TopupIcon from './assets/img/topup.png';
import WalletIcon from './assets/img/wallet.png';
import RefreshIcon from './assets/img/refresh.png';
import MenuIcon from './assets/img/menu-icon.png';


function App() {

  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const handleNavLinkClick = () => {
    setCollapsed(false);
    setToggled(false);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const { logout } = useLogout();
  const handleLogout = () => {
    logout();
    window.location.href= "/";
  }

  const { user } = useAuthContext();

  const [userData, setUserData] = useState();
  const getUserData = async () => {
    const response = await fetch('https://testapi.hiclub33.io/api/player/id/'+user.player_id, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
    const json = await response.json();
    if (response.ok) {
      console.log(json);
      setUserData(json);
    }
  }

  const { playerCreateDeposit, paymentLink, isLoading, error } = useDeposit();
  const [selectedGateway, setSelectedGateway] = useState('');
  const [depositAmount, setDepositAmount] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState('');
  const handleDeposit = async (e) => {
    setPaymentStatus("Pending");
		e.preventDefault();
		await playerCreateDeposit(
      user.token,
      user.player_id,
      userData.username,
      userData.referrer,
      "MYR",
      selectedGateway,
      depositAmount,
      false
    );
    // if (paymentLink) {
    //   window.location.href = paymentLink;
    // } else {
    //   setPaymentStatus("Link Error");
    // }
	};

  useEffect(() => {
    if (user) {
      getUserData();
    }
  }, [user]);

  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState('en');

  const handleLanguageChange = (language) => {
    setLanguage(language);
    setCurrentLanguage(language);
    setIsLanguageModalOpen(false);
  };

  const [isChatOptionOpen, setIsChatOptionOpen] = useState(false);
  const openChatOption = () => {
    setIsChatOptionOpen(true);
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Router>
      {/* Language Modal */}
      {isLanguageModalOpen && (
        <div
        className="modal fade show"
        style={{ display: "block", paddingTop: "100px", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-top" role="document">
          <div className="modal-content bg-black p-3">
            <div className="modal-header" style={{ borderBottom:'1px solid #e9d096'}}>
              <h5 className="modal-title brand-gold">Select Language</h5>
              <button
                type="button"
                className="btn-close bg-transparent"
                aria-label="Close"
                onClick={() => setIsLanguageModalOpen(false)}
                style={{ border:'none', fontSize:'18px' }}
              >
                <i className="fa-solid fa-circle-xmark brand-gold"></i>
              </button>
            </div>
            <div className="modal-body">
              <ul className="list-group">
                <li className="list-group-item border-0 bg-transparent">
                  <button
                    className={`btn btn-block text-start ${
                      currentLanguage === "en" ? "bg-gold" : "text-white"
                    }`}
                    onClick={() => handleLanguageChange("en")}
                  >
                    English
                  </button>
                </li>
                <li className="list-group-item border-0 bg-transparent">
                  <button
                    className={`btn btn-block text-start ${
                      currentLanguage === "cn" ? "bg-gold" : "text-white"
                    }`}
                    onClick={() => handleLanguageChange("cn")}
                  >
                    中文
                  </button>
                </li>
                <li className="list-group-item border-0 bg-transparent">
                  <button
                    className={`btn btn-block text-start ${
                      currentLanguage === "bm" ? "bg-gold" : "text-white"
                    }`}
                    onClick={() => handleLanguageChange("bm")}
                  >
                    Bahasa Melayu
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        </div>
      )}

      {/* Chat Option Modal */}
      {isChatOptionOpen && (
        <div
        className="modal fade show"
        style={{ display: "block", paddingTop: "100px", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-top" role="document">
          <div className="modal-content bg-black p-3">
            <div className="modal-header" style={{ borderBottom:'1px solid #e9d096'}}>
              <h5 className="modal-title brand-gold">Select Live Chat</h5>
              <button
                type="button"
                className="btn-close bg-transparent"
                aria-label="Close"
                onClick={() => setIsChatOptionOpen(false)}
                style={{ border:'none', fontSize:'18px' }}
              >
                <i className="fa-solid fa-circle-xmark brand-gold"></i>
              </button>
            </div>
            <div className="modal-body">
              <ul className="list-group">
                <li className="list-group-item border-0 bg-transparent">
                  <a href="https://wa.me/601111827726" target="_blank">
                    <button className="btn btn-block text-start bg-gold">
                      <i className="fa-brands fa-square-whatsapp mr-1"></i>Whatsapp
                    </button>
                  </a>
                </li>
                <li className="list-group-item border-0 bg-transparent">
                  <button className="btn btn-block text-start bg-gold" disabled>
                    <i className="fa-brands fa-telegram mr-1"></i>Telegram
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        </div>
      )}

      <div className="modal fade" id="createDepositModal" tabIndex="-1" role="dialog" aria-labelledby="createDeposit" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-lg" role="document">
					<div className="modal-content bg-black p-3">
						<div className="modal-header" style={{borderBottom: "none"}}>
							<h4 className="modal-title vip-yellow">
                <strong>Deposit</strong>
              </h4>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true"><i className="text-gray fa-solid fa-circle-xmark"></i></span>
							</button>
						</div>
            <div className="modal-body">
              <form className="w-100" onSubmit={handleDeposit}>
                <div className="mb-3">
                  <label htmlFor="gateway">
                    <h6 className="text-gray mb-0">Payment Method</h6>
                  </label>
                  <select 
                  name='gateway'
                  className='form-control'
                  onChange={(e) => setSelectedGateway(e.target.value)}
                  >
                    <option value="">Select Payment Method</option>
                    <option value="ZPAY">ZPAY (MYR)</option>
                    <option value="" disabled>Crypto Payment (Coming Soon)</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="amount">
                    <h6 className="text-gray mb-0">Amount</h6>
                  </label>
                  <input
                  type="number"
                  step="0.01"
                  placeholder='Enter Deposit Amount'
                  autoComplete='off'
                  name='amount'
                  className='form-control'
                  onChange={(e) => setDepositAmount(e.target.value)}
                  />
                </div>
                <button type="submit" disabled={selectedGateway == "" || depositAmount == 0} className="btn action-button w-100 mt-3">
                  <p className="mb-0"><strong>Submit</strong></p>
                </button>
                {error && <div className="error">{error}</div>}
              </form>
            </div>
					</div>
				</div>
			</div>

      {/* Header */}
      <section className={`header ${collapsed ? 'header-collapsed' : 'header-expanded'}`}>
        <div className="container-fluid">
          <div className="row align-items-center" style={{ height: '80px' }}>
            <div className="d-flex col-3 col-md-2 py-0 px-2 h-100 justify-content-center align-items-center">
              <NavLink to="/">
                <img className="side-menu-logotext" src={MainLogo}></img>
              </NavLink>
            </div>
            {!user ? (
              <div className="col h-100 w-100 p-0 text-center text-sm-right">
                <button className="btn header-button h-100 m-0 rounded-0">
                  <NavLink to="/login" className="d-flex align-items-center justify-content-center">
                    <img src={LoginIcon} />
                    <p className="mb-0 brand-gold"><strong>Login / Register</strong></p>
                  </NavLink>
                </button>
              </div>
            ):(
              <div className="col h-100 p-0 d-flex align-items-center">
                <button className="w-50 h-100 p-0 btn" data-toggle="modal" data-target="#createDepositModal" onClick={(e) => {
                }}>
                  <p className="mb-0 brand-gold user-wallet-icon">
                    <img src={TopupIcon} />
                    <strong>Deposit</strong>
                  </p>
                </button>
                <button className="w-50 h-100 p-0 btn" onClick={(e) => {
                  getUserData();
                }}> 
                  {userData ? (
                    <p className="mb-0 brand-gold user-wallet-icon">
                      <img src={WalletIcon} />
                      <strong>{userData && parseFloat(Number(userData.cashBalance)+Number(userData.creditBalance)).toFixed(2)}</strong>
                      <img src={RefreshIcon} className="ml-2"style={{ maxHeight:"20px"}} />
                    </p>
                  ):(
                    <p className="mb-0 brand-gold user-wallet-icon">
                      <img src={WalletIcon} />
                      <strong>Refresh</strong>
                      <img src={RefreshIcon} className="ml-2" style={{ maxHeight:"20px"}}/>
                    </p>
                  )}

                </button>
              </div>
            )}
            <button
              className="btn header-button h-100 m-0 rounded-0"
              // onClick={() => setIsLanguageModalOpen(true)}
            >
              <i className="fa-solid fa-bell brand-gold"></i>
            </button>
            <button
              className="btn header-button h-100 m-0 rounded-0"
              onClick={() => setIsLanguageModalOpen(true)}
            >
              <i className="fa-regular fa-globe lang brand-gold"></i>
            </button>
          </div>
        </div>
      </section>

      {/* Mobile Header */}
      <section className="header-mobile">
        <div className="container-fluid p-0">
          <div className="row align-items-center header-nav" style={{ height: '60px' }}>
            <div className="d-flex col h-100 align-items-center">
              <NavLink to="/">
                <img className="side-menu-logotext" src={MainLogo}></img>
              </NavLink>
            </div>
            {!user ? (
              <div className="col h-100 w-100 p-0 text-center text-sm-right">
                <button className="btn header-button h-100 m-0 rounded-0">
                  <NavLink to="/login" className="d-flex align-items-center justify-content-center">
                    <img src={LoginIcon} />
                    <p className="mb-0 brand-gold"><strong>Login / Register</strong></p>
                  </NavLink>
                </button>
              </div>
            ):(
              <div className="col h-100 p-0 d-flex align-items-center justify-content-end">
                <button className="h-100 mr-3 p-0 btn" data-toggle="modal" data-target="#createDepositModal" onClick={(e) => {
                }}>
                  <p className="mb-0 brand-gold user-wallet-icon">
                    <img src={TopupIcon} />
                    <strong>Deposit</strong>
                  </p>
                </button>
                <button className="h-100 p-0 btn" onClick={(e) => {
                  getUserData();
                }}> 
                  {userData ? (
                    <p className="mb-0 brand-gold user-wallet-icon">
                      <img src={WalletIcon} />
                      <strong>{userData && parseFloat(Number(userData.cashBalance)+Number(userData.creditBalance)).toFixed(2)}</strong>
                      <img src={RefreshIcon} className="ml-2" style={{ maxHeight:"15px"}} />
                    </p>
                  ):(
                    <p className="mb-0 brand-gold user-wallet-icon">
                      <img src={WalletIcon} />
                      <strong>Refresh</strong>
                      <img src={RefreshIcon} className="ml-2" style={{ maxHeight:"15px"}}/>
                    </p>
                  )}
                </button>
              </div>
            )}
          </div>
          
          {/* <div className="row d-flex" style={{ margin:"10px"}}>
            <div className="search-bar col-10 p-0">
              <input
                type="text"
                className="search-input"
                // value={searchQuery}
                // onChange={(e) => setSearchQuery(e.target.value)}
              />
              <i className="fa-solid fa-magnifying-glass brand-gold"></i>
            </div>

            <button
              className="btn col-2 h-100 m-0 rounded-0 "
              onClick={() => setIsLanguageModalOpen(true)}
            >
              <i className="fa-regular fa-globe lang brand-gold p-0" style={{ fontSize:"20px"}}></i>
            </button>
          </div> */}
        
        </div>
      </section>

      {/* Mobile Bottom Nav */}
      <section className="mobile-nav">
        <div className="container-fluid w-100 d-flex justify-content-center my-3 p-0">
          <div className="text-center"> 
            <button className="btn collapse-menu-btn" onClick={() => setToggled(!toggled)}>
              <img className="collapse-btn-img" src={MenuIcon} />
            </button>
            <p className="vip-yellow text-center mt-2 m-0">Menu</p>
          </div>
          <div className="text-center">
            <NavLink to="/game/category/mobile-slot-games">
              <button className="btn nav-icon">
                <i className="fa-light fa-game-console-handheld"></i>
              </button>
            </NavLink>
            <p className="vip-yellow mt-2 m-0">Mobile Games</p>
          </div>
          <div className="text-center">
            <NavLink to="/game/category/casino">
              <button className="btn nav-icon">
                <i className="fa-regular fa-cards"></i>
              </button>
            </NavLink>
            <p className="vip-yellow mt-2 m-0">Casino</p>
          </div>
          <div className="text-center">
            <NavLink to="/">
              <button disabled className="btn nav-icon">
                <i className="fa-sharp fa-solid fa-trophy-star"></i>
              </button>
            </NavLink>
            <p className="vip-yellow mt-2 m-0">Sports</p>
          </div>
          <div className="text-center">
            {/* <a href="https://wa.me/601111827726" target="_blank"> */}
              <button className="btn nav-icon" onClick={openChatOption}>
                <i className="fa-solid fa-headset"></i>
              </button>
            {/* </a> */}
            <p className="vip-yellow mt-2 m-0">Live Chat</p>
          </div>
        </div>
      </section>

      <ContactUsIcon/>
      
      <div className="d-flex">
        <Sidebar 
          className="d-none d-md-block"
          collapsed={collapsed}
          transitionDuration={600}
          width="260px"
          backgroundColor="#13171a"
          breakPoint="md"
          onBackdropClick={() => setToggled(false)}
          toggled={toggled}
          rootStyles={{
            // paddingTop: '60px',
            height: '100vh',
            zIndex: '110',
            // borderRight: '1px solid #e9d096',
          }}
        > 

          <Menu
            renderExpandIcon={({ open }) => 
              <span className="collapse-icon">
                {open ? <i className="fa-regular fa-caret-down mt-2"></i> : <i className="fa-regular fa-caret-right mt-2"></i>}
              </span>
            }
            rootStyles={{
              ['.' + menuClasses.button]: {
                backgroundColor: '#13171a',
                color: '#DCDCDC',
                '&:hover': {
                  backgroundColor: '#a17b1c',
                },
              },
              ['.active']: {
                backgroundColor: '#a17b1c',
              }
            }}
          >
            <div className="d-flex d-sm-none p-3 justify-content-center align-items-center">
              <NavLink to="/" style={{backgroundColor: 'transparent', textAlign:'center'}}>
                <img className="side-menu-logotext" src={MainLogo}></img>
              </NavLink>
            </div>
            <MenuItem 
            // style={{ height: `${headerHeight}px` }}
              icon={
                <span className="side-menu-icon">
                  <i className={!collapsed ? "fa-regular fa-angles-left" : "fa-regular fa-angles-right"}></i>
                </span>
              }
              onClick={() => setCollapsed(!collapsed)}
            > 
              <p className="side-menu-text">Collapse Menu</p>
            </MenuItem>

            <MenuItem 
              icon={<span className="side-menu-icon"><i className="fa-regular fa-fire"></i></span>}
              component={<NavLink to="/" activeClassName={['menu-item'].active}/>}
            > 
              <p className="side-menu-text">Hot Pick</p>
            </MenuItem>

            {/* <MenuItem 
              icon={<span className="side-menu-icon"><i className="fa-regular fa-solid fa-clock-rotate-left"></i></span>}
              component={<NavLink to="/recent" activeClassName={['menu-item'].active}/>}
            > 
              <p className="side-menu-text">Recent</p>
            </MenuItem> */}

            <MenuItem
              icon={<span className="side-menu-icon"><i className="fa-regular fa-game-console-handheld"></i></span>}
              component={<NavLink to="/game/category/mobile-slot-games" activeClassName={['menu-item'].active}/>}
            > 
              <p className="side-menu-text">Mobile Games</p>
            </MenuItem>

            <SubMenu
            rootStyles={{
              ['& > .' + menuClasses.button]: {
                backgroundColor: '#202629',
              },
              ['.' + menuClasses.subMenuContent]: {
                backgroundColor: '#202629',
              },
            }}
              icon={<span className="side-menu-icon"><i className="fa-regular fa-gamepad-modern"></i></span>} 
              label={<p className="side-menu-text">Games</p>}
            >
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-regular fa-slot-machine"></i></span>}
                component={<NavLink to="/game/category/slots" activeClassName={['menu-item'].active} />}
              > 
                <p className="side-menu-text">Slots</p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-regular fa-fishing-rod"></i></span>}
                component={<NavLink to="/game/category/fishing" activeClassName={['menu-item'].active}/>}
              > 
                <p className="side-menu-text">Fishing</p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-regular fa-dice"></i></span>}
                component={<NavLink to="/game/category/bingo" activeClassName={['menu-item'].active}/>}
              > 
                <p className="side-menu-text">Bingo</p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-regular fa-dice"></i></span>}
                component={<NavLink to="/game/category/casino" activeClassName={['menu-item'].active}/>}
              > 
                <p className="side-menu-text">Casino</p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-regular fa-cards"></i></span>}
                component={<NavLink to="/game/category/poker" activeClassName={['menu-item'].active}/>}
              > 
                <p className="side-menu-text">Poker</p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-regular fa-cards"></i></span>}
                component={<NavLink to="/game/category/table" activeClassName={['menu-item'].active}/>}
              > 
                <p className="side-menu-text">Table Game</p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-solid fa-alien-8bit"></i></span>}
                component={<NavLink to="/game/category/arcade" activeClassName={['menu-item'].active}/>}
              > 
                <p className="side-menu-text">Arcade</p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-regular fa-trophy-star"></i></span>}
              > 
                <p className="side-menu-text">Racing<span className="side-menu-coming-soon ml-2">Soon</span></p>
              </MenuItem>
            </SubMenu>

            <SubMenu
            rootStyles={{
              ['& > .' + menuClasses.button]: {
                backgroundColor: '#202629',
              },
              ['.' + menuClasses.subMenuContent]: {
                backgroundColor: '#202629',
              },
            }}
                icon={<span className="side-menu-icon"><i className="fa-regular fa-court-sport"></i></span>} 
                label={<p className="side-menu-text">Sports Games<span className="side-menu-coming-soon ml-2">Soon</span></p>}
            >
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-regular fa-futbol"></i></span>}
              > 
                <p className="side-menu-text">Soccer<span className="side-menu-coming-soon ml-2">Soon</span></p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-regular fa-basketball-hoop"></i></span>}
              > 
                <p className="side-menu-text">Basketball<span className="side-menu-coming-soon ml-2">Soon</span></p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-regular fa-tennis-ball"></i></span>}
              > 
                <p className="side-menu-text">Tennis<span className="side-menu-coming-soon ml-2">Soon</span></p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-regular fa-sportsball"></i></span>}
              > 
                <p className="side-menu-text">Football<span className="side-menu-coming-soon ml-2">Soon</span></p>
              </MenuItem>
            </SubMenu>

            <MenuItem 
              icon={<span className="side-menu-icon"><i className="fa-regular fa-ticket"></i></span>}
            > 
              <p className="side-menu-text">Lottery<span className="side-menu-coming-soon ml-2">Soon</span></p>
            </MenuItem>

            <MenuItem 
              icon={<span className="side-menu-icon"><i className="fa-solid fa-badge-percent"></i></span>}
              component={<NavLink to="/promotions" activeClassName={['menu-item'].active}/>}
            > 
              <p className="side-menu-text">Promotions</p>
            </MenuItem>

            <MenuItem 
              icon={<span className="side-menu-icon"><i className="fa-regular fa-gift"></i></span>}
              component={<NavLink to="/bonus" activeClassName={['menu-item'].active}/>}
            > 
              <p className="side-menu-text">Bonus</p>
            </MenuItem>

            <MenuItem 
              icon={<span className="side-menu-icon"><i className="fa-solid fa-network-wired"></i></span>}
              component={<NavLink to="/affiliate" activeClassName={['menu-item'].active}/>}
            > 
              <p className="side-menu-text">Affiliate Program</p>
            </MenuItem>

            {user ? (
              <>
                <MenuItem 
                  icon={<span className="side-menu-icon"><i className="fa-solid fa-user"></i></span>}
                  component={<NavLink to="/profile" activeClassName={['menu-item'].active}/>}
                > 
                  <p className="side-menu-text">Profile</p>
                </MenuItem>

                <MenuItem 
                  icon={<span className="side-menu-icon"><i className="fa-solid fa-wallet"></i></span>}
                  component={<NavLink to="/wallet" activeClassName={['menu-item'].active}/>}
                > 
                  <p className="side-menu-text">Wallet</p>
                </MenuItem>

                <MenuItem 
                  icon={<span className="side-menu-icon"><i className="fa-solid fa-right-from-bracket"></i></span>}
                  onClick={handleLogout}
                > 
                  <p className="side-menu-text">Log Out</p>
                </MenuItem>
              </>
            ):(
              <></>
            )}

          </Menu>

        </Sidebar>

        <Sidebar 
          className="d-block d-md-none"
          collapsed={collapsed}
          collapsedWidth="100px"
          transitionDuration={600}
          width="100%"
          backgroundColor="#292732"
          breakPoint="md"
          onBackdropClick={() => setToggled(false)}
          toggled={toggled}
          rootStyles={{
            // paddingTop: '60px',
            height: '100vh',
            zIndex: '110',
            border: 'none',
          }}
        > 

          <Menu
            renderExpandIcon={({ open }) => 
              <span className="collapse-icon">
                {open ? <i className="fa-regular fa-caret-down mt-2"></i> : <i className="fa-regular fa-caret-right mt-2"></i>}
              </span>
            }
            rootStyles={{
              ['.' + menuClasses.button]: {
                backgroundColor: '#1d1c28',
                borderRadius: '10px',
                margin: '10px',
                marginBottom:'0px',
                color: '#e9d096',
                '&:hover': {
                  backgroundColor: '#eea53f',
                },
              },
              ['.active']: {
                color: 'white',
                backgroundColor: '#eea53f',
              }
            }}
          >
            <div className="d-flex d-sm-none p-3 align-items-center">
              <NavLink to="/" onClick={handleNavLinkClick} style={{backgroundColor: 'transparent', textAlign:'center'}}>
                <img className="side-menu-logotext" src={MainLogo}></img>
              </NavLink>
            </div>
            <MenuItem 
            // style={{ height: `${headerHeight}px` }}
              icon={
                <span className="side-menu-icon">
                  <i className={!collapsed ? "fa-regular fa-angles-left" : "fa-regular fa-angles-right"}></i>
                </span>
              }
              onClick={() => setCollapsed(!collapsed)}
            > 
              <p className="side-menu-text">Collapse Menu</p>
            </MenuItem>

            <MenuItem 
              icon={<span className="side-menu-icon"><i className="fa-regular fa-fire"></i></span>}
              component={<NavLink to="/" onClick={handleNavLinkClick} activeClassName={['menu-item'].active}/>}
            > 
              <p className="side-menu-text">Hot Pick</p>
            </MenuItem>

            {/* <MenuItem 
              icon={<span className="side-menu-icon"><i className="fa-regular fa-solid fa-clock-rotate-left"></i></span>}
              component={<NavLink to="/recent" activeClassName={['menu-item'].active}/>}
            > 
              <p className="side-menu-text">Recent</p>
            </MenuItem> */}

            <MenuItem
              icon={<span className="side-menu-icon"><i className="fa-regular fa-game-console-handheld"></i></span>}
              component={<NavLink to="/game/category/mobile-slot-games" onClick={handleNavLinkClick} activeClassName={['menu-item'].active}/>}
            > 
              <p className="side-menu-text">Mobile Games</p>
            </MenuItem>

            <SubMenu
            rootStyles={{
              ['& > .' + menuClasses.button]: {
                backgroundColor: '#202629',
              },
              ['.' + menuClasses.subMenuContent]: {
                backgroundColor: '#202629',
              },
            }}
              icon={<span className="side-menu-icon"><i className="fa-regular fa-gamepad-modern"></i></span>} 
              label={<p className="side-menu-text">Games</p>}
            >
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-regular fa-slot-machine"></i></span>}
                component={<NavLink to="/game/category/slots" onClick={handleNavLinkClick} activeClassName={['menu-item'].active}/>}
              > 
                <p className="side-menu-text">Slots</p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-regular fa-fishing-rod"></i></span>}
                component={<NavLink to="/game/category/fishing" onClick={handleNavLinkClick} activeClassName={['menu-item'].active}/>}
              > 
                <p className="side-menu-text">Fishing</p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-regular fa-dice"></i></span>}
                component={<NavLink to="/game/category/bingo" onClick={handleNavLinkClick} activeClassName={['menu-item'].active}/>}
              > 
                <p className="side-menu-text">Bingo</p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-regular fa-dice"></i></span>}
                component={<NavLink to="/game/category/casino" onClick={handleNavLinkClick} activeClassName={['menu-item'].active}/>}
              > 
                <p className="side-menu-text">Casino</p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-regular fa-cards"></i></span>}
                component={<NavLink to="/game/category/poker" onClick={handleNavLinkClick} activeClassName={['menu-item'].active}/>}
              > 
                <p className="side-menu-text">Poker</p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-regular fa-cards"></i></span>}
                component={<NavLink to="/game/category/table" onClick={handleNavLinkClick} activeClassName={['menu-item'].active}/>}
              > 
                <p className="side-menu-text">Table Game</p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-solid fa-alien-8bit"></i></span>}
                component={<NavLink to="/game/category/arcade" onClick={handleNavLinkClick} activeClassName={['menu-item'].active}/>}
              > 
                <p className="side-menu-text">Arcade</p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-regular fa-trophy-star"></i></span>}
              > 
                <p className="side-menu-text">Racing<span className="side-menu-coming-soon ml-2">Soon</span></p>
              </MenuItem>
            </SubMenu>

            <SubMenu
            rootStyles={{
              ['& > .' + menuClasses.button]: {
                backgroundColor: '#202629',
              },
              ['.' + menuClasses.subMenuContent]: {
                backgroundColor: '#202629',
              },
            }}
                icon={<span className="side-menu-icon"><i className="fa-regular fa-court-sport"></i></span>} 
                label={<p className="side-menu-text">Sports Games<span className="side-menu-coming-soon ml-2">Soon</span></p>}
            >
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-regular fa-futbol"></i></span>}
              > 
                <p className="side-menu-text">Soccer<span className="side-menu-coming-soon ml-2">Soon</span></p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-regular fa-basketball-hoop"></i></span>}
              > 
                <p className="side-menu-text">Basketball<span className="side-menu-coming-soon ml-2">Soon</span></p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-regular fa-tennis-ball"></i></span>}
              > 
                <p className="side-menu-text">Tennis<span className="side-menu-coming-soon ml-2">Soon</span></p>
              </MenuItem>
              <MenuItem
                icon={<span className="side-menu-icon"><i className="fa-regular fa-sportsball"></i></span>}
              > 
                <p className="side-menu-text">Football<span className="side-menu-coming-soon ml-2">Soon</span></p>
              </MenuItem>
            </SubMenu>

            <MenuItem 
              icon={<span className="side-menu-icon"><i className="fa-regular fa-ticket"></i></span>}
            > 
              <p className="side-menu-text">Lottery<span className="side-menu-coming-soon ml-2">Soon</span></p>
            </MenuItem>

            <MenuItem 
              icon={<span className="side-menu-icon"><i className="fa-solid fa-badge-percent"></i></span>}
              component={<NavLink to="/promotions" onClick={handleNavLinkClick} activeClassName={['menu-item'].active}/>}
            > 
              <p className="side-menu-text">Promotions</p>
            </MenuItem>

            <MenuItem 
              icon={<span className="side-menu-icon"><i className="fa-regular fa-gift"></i></span>}
              component={<NavLink to="/bonus" onClick={handleNavLinkClick} activeClassName={['menu-item'].active}/>}
            > 
              <p className="side-menu-text">Bonus</p>
            </MenuItem>

            <MenuItem 
              icon={<span className="side-menu-icon"><i className="fa-solid fa-network-wired"></i></span>}
              component={<NavLink to="/affiliate" onClick={handleNavLinkClick} activeClassName={['menu-item'].active}/>}
            > 
              <p className="side-menu-text">Affiliate Program</p>
            </MenuItem>

            {user ? (
              <>
                <MenuItem 
                  icon={<span className="side-menu-icon"><i className="fa-solid fa-user"></i></span>}
                  component={<NavLink to="/profile" onClick={handleNavLinkClick} activeClassName={['menu-item'].active}/>}
                > 
                  <p className="side-menu-text">Profile</p>
                </MenuItem>

                <MenuItem 
                  icon={<span className="side-menu-icon"><i className="fa-solid fa-wallet"></i></span>}
                  component={<NavLink to="/wallet" onClick={handleNavLinkClick} activeClassName={['menu-item'].active}/>}
                > 
                  <p className="side-menu-text">Wallet</p>
                </MenuItem>

                <MenuItem 
                  icon={<span className="side-menu-icon"><i className="fa-solid fa-right-from-bracket"></i></span>}
                  onClick={handleLogout}
                > 
                  <p className="side-menu-text">Log Out</p>
                </MenuItem>
              </>
            ):(
              <></>
            )}

            <MenuItem 
              icon={<span className="side-menu-icon"><i className="fa-regular fa-globe lang brand-gold"></i></span>}
              onClick={() => setIsLanguageModalOpen(true)}
            > 
              <p className="side-menu-text">{t('English')}</p>
            </MenuItem>

            <MenuItem 
              icon={<span className="side-menu-icon"><i className="fa-solid fa-bell brand-gold"></i></span>}
            > 
              <p className="side-menu-text">Notification</p>
            </MenuItem>

            {/* <button
              className="btn header-button h-100 m-0 rounded-0 gold-border-left"
              onClick={() => setIsLanguageModalOpen(true)}
            >
              <i className="fa-regular fa-globe lang brand-gold"></i>
            </button> */}

          </Menu>

        </Sidebar>

        <main className="main-content">

          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/login" element={!user ? <Login/> : <Navigate to="/" />}/>
            <Route path="/register" element={!user ? <Register/> : <Navigate to="/" />}/>
            <Route path="/register/:referrerUsername" element={!user ? <Register/> : <Navigate to="/" />}/>

            <Route path="/recent" element={<RecentPage/>}/>

            {/* Games */}
            <Route path="/game/category/mobile-slot-games" element={<MobileSlotGame/>}/>
            <Route path="/game/category/slots" element={<SlotMachine/>}/>
            <Route path="/game/category/fishing" element={<FishingGame/>}/>
            <Route path="/game/category/bingo" element={<BingoGame/>}/>
            <Route path="/game/category/casino" element={<CasinoGame/>}/>
            <Route path="/game/category/poker" element={<PokerGame/>}/>
            <Route path="/game/category/table" element={<TableGame/>}/>
            <Route path="/game/category/arcade" element={<ArcadeGame/>}/>

            <Route path="/profile" element={<PlayerProfile/>}/>
            <Route path="/wallet" element={<PlayerWallet/>}/>
            <Route
              path="/promotions"
              element={<Promotions/>}
            />
            <Route
              path="/bonus"
              element={<Bonus/>}
            />
            <Route
              path="/affiliate"
              element={<Affiliate/>}
            />
            <Route
              path="/leaderboard"
              element={<Leaderboard/>}
            />
            <Route path="/terms" element={<TermsAndCondition/>}/>
            <Route path="/privacy" element={<PrivacyPolicy/>}/>
          </Routes>
          <Footer/>
        </main>

      </div>

    </Router>
  );
}

export default App;